@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?j9dgkz');
  src: url('fonts/icomoon.eot?j9dgkz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?j9dgkz') format('truetype'),
    url('fonts/icomoon.woff?j9dgkz') format('woff'),
    url('fonts/icomoon.svg?j9dgkz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BuyFull:before {
  content: '\e9a2';
}
.icon-ItemsFull:before {
  content: '\e9a3';
}
.icon-Bulb:before {
  content: '\e99c';
}
.icon-marketing:before {
  content: '\e99d';
}
.icon-rocket:before {
  content: '\e99e';
}
.icon-shine:before {
  content: '\e99f';
}
.icon-Shoppable:before {
  content: '\e9a0';
}
.icon-wifi:before {
  content: '\e9a1';
}
.icon-Unpin:before {
  content: '\e99b';
}
.icon-Coins:before {
  content: '\e99a';
}
.icon-SoundOff:before {
  content: '\e992';
}
.icon-SoundOn:before {
  content: '\e993';
}
.icon-Cart:before {
  content: '\e999';
}
.icon-Pause:before {
  content: '\e994';
}
.icon-DefaultPlay:before {
  content: '\e995';
}
.icon-Reply:before {
  content: '\e996';
}
.icon-VideoBack:before {
  content: '\e997';
}
.icon-VideoNext:before {
  content: '\e998';
}
.icon-LockedNotVotedPoll:before {
  content: '\e98d';
}
.icon-LockedVotedPoll:before {
  content: '\e98e';
}
.icon-NotSeenPoll:before {
  content: '\e98f';
}
.icon-NotVotedPoll:before {
  content: '\e990';
}
.icon-VotedPoll:before {
  content: '\e991';
}
.icon-Minimize:before {
  content: '\e98a';
}
.icon-NoWifi:before {
  content: '\e98b';
}
.icon-PictureInPicture:before {
  content: '\e98c';
}
.icon-Happy:before {
  content: '\e987';
}
.icon-Neutral:before {
  content: '\e988';
}
.icon-Sad:before {
  content: '\e989';
}
.icon-Wifi:before {
  content: '\e986';
}
.icon-MaxViewers:before {
  content: '\e985';
}
.icon-HeartFill:before {
  content: '\e984';
}
.icon-LiveTag:before {
  content: '\e983';
}
.icon-User:before {
  content: '\e900';
}
.icon-User1:before {
  content: '\e901';
}
.icon-Activity:before {
  content: '\e902';
}
.icon-AddUser:before {
  content: '\e903';
}
.icon-Apps:before {
  content: '\e904';
}
.icon-ArrowDown:before {
  content: '\e905';
}
.icon-ArrowDown2:before {
  content: '\e906';
}
.icon-ArrowDownCircle:before {
  content: '\e907';
}
.icon-ArrowDownSquare:before {
  content: '\e908';
}
.icon-ArrowLeft:before {
  content: '\e909';
}
.icon-ArrowLeft2:before {
  content: '\e90a';
}
.icon-ArrowLeftCircle:before {
  content: '\e90b';
}
.icon-ArrowLeftSquare:before {
  content: '\e90c';
}
.icon-ArrowRedo:before {
  content: '\e90d';
}
.icon-ArrowRight:before {
  content: '\e90e';
}
.icon-ArrowRight2:before {
  content: '\e90f';
}
.icon-ArrowRightCircle:before {
  content: '\e910';
}
.icon-ArrowRightSquare:before {
  content: '\e911';
}
.icon-ArrowUndo:before {
  content: '\e912';
}
.icon-ArrowUp:before {
  content: '\e913';
}
.icon-ArrowUp2:before {
  content: '\e914';
}
.icon-ArrowUpandDown:before {
  content: '\e957';
}
.icon-ArrowUpCircle:before {
  content: '\e916';
}
.icon-ArrowUpSquare:before {
  content: '\e917';
}
.icon-AtSign:before {
  content: '\e918';
}
.icon-Bag:before {
  content: '\e919';
}
.icon-Bluetooth:before {
  content: '\e91a';
}
.icon-Bookmark:before {
  content: '\e91b';
}
.icon-BurgerMenu:before {
  content: '\e91c';
}
.icon-Buy:before {
  content: '\e91d';
}
.icon-Calendar:before {
  content: '\e91e';
}
.icon-Call:before {
  content: '\e91f';
}
.icon-Calling:before {
  content: '\e920';
}
.icon-Camera:before {
  content: '\e921';
}
.icon-Category:before {
  content: '\e922';
}
.icon-Chat:before {
  content: '\e923';
}
.icon-Close:before {
  content: '\e924';
}
.icon-CloudDownload:before {
  content: '\e925';
}
.icon-CloudOff:before {
  content: '\e926';
}
.icon-CloudUpload:before {
  content: '\e927';
}
.icon-copy:before {
  content: '\e928';
}
.icon-Delete:before {
  content: '\e929';
}
.icon-Diamond:before {
  content: '\e92a';
}
.icon-Disabled:before {
  content: '\e96d';
}
.icon-Download:before {
  content: '\e92c';
}
.icon-Edit:before {
  content: '\e92d';
}
.icon-envolpeOpen:before {
  content: '\e92e';
}
.icon-Error:before {
  content: '\e92f';
}
.icon-Facebook:before {
  content: '\e930';
}
.icon-Filter-2:before {
  content: '\e931';
}
.icon-Filter:before {
  content: '\e932';
}
.icon-Folder:before {
  content: '\e933';
}
.icon-fullscreen:before {
  content: '\e934';
}
.icon-globe:before {
  content: '\e935';
}
.icon-GoogleAlt:before {
  content: '\e936';
}
.icon-Graph:before {
  content: '\e937';
}
.icon-HeartOutline:before {
  content: '\e938';
}
.icon-Help:before {
  content: '\e939';
}
.icon-Hide:before {
  content: '\e93a';
}
.icon-History:before {
  content: '\e93b';
}
.icon-Home-1:before {
  content: '\e93c';
}
.icon-Home:before {
  content: '\e93d';
}
.icon-Image2:before {
  content: '\e93e';
}
.icon-Info:before {
  content: '\e93f';
}
.icon-Instagram:before {
  content: '\e940';
}
.icon-itemCustomization:before {
  content: '\e980';
}
.icon-Key:before {
  content: '\e942';
}
.icon-Language:before {
  content: '\e943';
}
.icon-Lightning:before {
  content: '\e944';
}
.icon-LightningAlt:before {
  content: '\e945';
}
.icon-link:before {
  content: '\e946';
}
.icon-link02:before {
  content: '\e947';
}
.icon-Location:before {
  content: '\e948';
}
.icon-Lock:before {
  content: '\e949';
}
.icon-Login:before {
  content: '\e94a';
}
.icon-Logout:before {
  content: '\e94b';
}
.icon-Message:before {
  content: '\e94c';
}
.icon-MessageCircle:before {
  content: '\e94d';
}
.icon-MessageRound:before {
  content: '\e94e';
}
.icon-minus:before {
  content: '\e94f';
}
.icon-more_horisontal:before {
  content: '\e950';
}
.icon-moreVertical:before {
  content: '\e951';
}
.icon-Move:before {
  content: '\e952';
}
.icon-Notification:before {
  content: '\e953';
}
.icon-package:before {
  content: '\e954';
}
.icon-Paper-Plus:before {
  content: '\e955';
}
.icon-Paper:before {
  content: '\e956';
}
.icon-PaperDownload:before {
  content: '\e981';
}
.icon-PaperFail:before {
  content: '\e958';
}
.icon-PaperNegative:before {
  content: '\e959';
}
.icon-PaperUpload:before {
  content: '\e95a';
}
.icon-Password:before {
  content: '\e95b';
}
.icon-paste:before {
  content: '\e95c';
}
.icon-paymentCard:before {
  content: '\e95d';
}
.icon-Percentage:before {
  content: '\e95e';
}
.icon-pin:before {
  content: '\e95f';
}
.icon-Play:before {
  content: '\e960';
}
.icon-plus:before {
  content: '\e961';
}
.icon-Present:before {
  content: '\e962';
}
.icon-Profile:before {
  content: '\e963';
}
.icon-Refresh:before {
  content: '\e964';
}
.icon-REPEAT:before {
  content: '\e965';
}
.icon-Resize:before {
  content: '\e966';
}
.icon-SandCloack:before {
  content: '\e967';
}
.icon-Scan:before {
  content: '\e968';
}
.icon-Search:before {
  content: '\e969';
}
.icon-Send:before {
  content: '\e96a';
}
.icon-Setting:before {
  content: '\e96b';
}
.icon-shareOutline:before {
  content: '\e96c';
}
.icon-ShieldDone:before {
  content: '\e982';
}
.icon-ShieldFail:before {
  content: '\e96e';
}
.icon-Shipment-filled:before {
  content: '\e96f';
}
.icon-Show:before {
  content: '\e970';
}
.icon-Star:before {
  content: '\e971';
}
.icon-Success:before {
  content: '\e972';
}
.icon-Swap:before {
  content: '\e973';
}
.icon-thumbs-up:before {
  content: '\e974';
}
.icon-Time-Circle:before {
  content: '\e975';
}
.icon-Twitter:before {
  content: '\e976';
}
.icon-Unlock:before {
  content: '\e977';
}
.icon-Upload:before {
  content: '\e978';
}
.icon-Video:before {
  content: '\e979';
}
.icon-Voice:before {
  content: '\e97a';
}
.icon-VoiceMute:before {
  content: '\e97b';
}
.icon-Whatsapp:before {
  content: '\e97c';
}
.icon-Youtube:before {
  content: '\e97d';
}
.icon-ZoomIn:before {
  content: '\e97e';
}
.icon-ZoomOut:before {
  content: '\e97f';
}
.icon-Celebration:before {
  content: '\e915';
}
.icon-Dollar:before {
  content: '\e92b';
}
.icon-Quiz:before {
  content: '\e941';
}
