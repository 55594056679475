// Helpers
@import '../helpers';
.main-content.live-session-host {
  justify-content: space-evenly;
  margin: 1.25vw;
  //////////////////////////////////////////
  // Actions
  //////////////////////////////////////////
  .actions-wrapper {
    overflow-x: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding: 1.25vw;
    .action-tab-content {
      height: 100%;
      .tab-pane {
        height: 100%;
        .tab-inner {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      .tab-pane::-webkit-scrollbar-thumb {
        height: 100%;
        .tab-inner {
          height: 100%;
          display: flex;
          flex-direction: column;
          scrollbar-color: #b0b5bf;
          border-radius: 100px;
          background: #8070d4;
          border: 6px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .tabs-content {
    flex: 1 1 100%;
    padding: 1.25vw;
    .tab-pane {
      height: 100%;
      .tab-inner {
        .product-wrapper {
          .product-container {
            display: flex;
          }
          .product-image-wrapper {
            position: relative;
            width: get-vw-desktop(200px);
            height: unset;
          }
          .product-description {
            padding: 0;
            margin: 0;
            flex: 0 0 100%;
          }
          .extra-info {
            padding: 0;
            margin: 0;
            border: 0;
          }
        }
        .products-wrapper {
          .products {
            .product-item {
              flex: 0 0 calc(92% / 3);
            }
          }
        }
      }
    }
  }

  //////////////////////////////////////////
  // caemara wrapper
  //////////////////////////////////////////
  .camera-feed-wrapper-desktop {
    flex: 0 0 calc(var(--vh, 1vh) * 46.1);
  }
}

.product-item {
  border: double;
  border-radius: 1.625vw;
  display: inline-block;
  margin: 1rem;
}

.video-cards {
  position: relative;
  padding: 0.417vw;
  flex: 0 0 calc(25% - 0.694vw);
  transition: all 300ms ease-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 13em;
  border-radius: 10px;
  background-size: inherit;
  background-repeat: repeat-x;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-around;
  width: 114px;
  height: 197px;
}

.containerAllVideos {
  white-space: pre;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow-x: auto;
  height: auto;

  .video-item {
    margin: 1rem;
    flex-direction: column;
    background-size: 101% 100%;
    width: 156px;
    white-space: break-spaces;
    height: 100%;
    .playIcon {
      opacity: 0;
    }
    .video-cards:hover {
      .playIcon {
        margin-top: 50%;
        opacity: 1;
        // padding: 50px 0px 0px 0px;
        height: 23%;
        align-self: center;
        inline-size: -webkit-fill-available;
      }
    }
    .infoVideo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      width: 167px;
      .videoName {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        color: #ffffff;
      }
      .videoLength {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
  }
}

.videoInSession {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

// .ng-scroll-content {
//     // height: 100%;
//     display: inline-flex;
//     flex-wrap: wrap;
// }
.progress-bar-desktop-host {
  margin: 1.25vw;
  margin-inline-start: 0;
}
