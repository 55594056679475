//////////////////////////////////////////
// Desktop session
//////////////////////////////////////////
.session-screen-desktop {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #fff;

  //////////////////////////////////////////
  // Sidebar nav
  //////////////////////////////////////////
  .sidebar-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.944vw 0;
    flex: 0 0 6.667vw;
    z-index: 3;

    .circle-sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.389vw;
      color: #191d38;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 0.833vw;
      line-height: 120%;

      .circle-bg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.833vw;
        width: 3.611vw;
        height: 3.611vw;
        background-color: #ffffff;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .text {
        padding: 0 5%;
        word-break: break-word;
      }

      &.btn-brand {
        .circle-bg {
          border: 0;
          box-shadow: 0px 0.278vw 0.625vw rgba(0, 0, 0, 0.25);

          img {
            width: 2.361vw;
            object-fit: contain;
          }
        }
      }

      &.btn-host {
        .circle-bg {
          overflow: visible;
          $border: 2px;
          position: relative;
          background-clip: padding-box; /* !importanté */
          border: solid $border transparent; /* !importanté */

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: linear-gradient(
              229.08deg,
              var(--secondary-store) 39.13%,
              var(--primary-store) 80.26%
            );
          }

          img {
            border-radius: 50%;
          }
        }
      }

      &.btn-user {
        position: relative;
        margin-top: 1.667vw;
        margin-bottom: 0;
        padding: 0;
        height: auto;
        background-image: none;
        border-radius: unset;
        .circle-bg {
          margin-bottom: 0;
          border: 1px solid #ffffff;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        }

        &::after {
          display: none;
        }
      }
    }

    .btn-gray,
    .btn-share-sidebar {
      margin-top: 1.667vw;
      color: #191d38;
      font-style: normal;
      font-weight: 500;
      font-size: 0.833vw;
      line-height: 120%;

      .circle-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.347vw;
        width: 2.917vw;
        height: 2.917vw;
        background-color: #f6f7fb;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 1.667vw;
          height: 1.667vw;
          object-fit: cover;
          object-position: center;
        }
      }

      &.btn-like {
        .circle-bg {
          position: relative;
          background-color: #f6f7fb;
          overflow: visible;

          .lottie-like {
            position: absolute;
            width: 4.1vw;
            bottom: 0.1vw;
            pointer-events: none;
          }
        }

        &.liked {
          .circle-bg {
            background-image: linear-gradient(
              180deg,
              var(--secondary-store) 0%,
              var(--primary-store) 100%
            );
          }
        }
      }
    }

    app-like-button {
      margin-top: auto;
    }

    .dropdown {
      position: static;
    }

    .user-dropdown {
      position: absolute;
      top: auto;
      left: 6.667vw;
      bottom: 1.944vw;
      z-index: 1;
      .rtl & {
        left: unset;
        right: 6.667vw;
      }
    }
  }

  //////////////////////////////////////////
  // Main content
  //////////////////////////////////////////
  .main-content {
    display: flex;
    flex: 1 1 100%;
    background-color: #f6f7fb;
    border-radius: 1.389vw;
    overflow: hidden;

    .label {
      margin-bottom: 14px;
      color: #333838;
      font-size: get-vw-desktop(23px);
      line-height: get-vw-desktop(34px);
    }
  }

  //////////////////////////////////////////
  // Card sidebar
  //////////////////////////////////////////
  .cart-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.528vw 1.389vw;
    flex: 0 0 13.889vw;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 1.042vw;
      line-height: 1.528vw;

      i {
        margin-right: 0.833vw;
        font-size: 1.806vw;
        color: var(--primary-store);

        .rtl & {
          margin-right: unset;
          margin-left: 0.833vw;
        }
      }
    }

    .seperator {
      margin: 1.25vw 0;
      border: 1px solid #b0b5bf;
    }

    //////////////////////////////////////////
    // Cart Scroller
    //////////////////////////////////////////
    .cart-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      padding: 0.972vw 0.417vw 0;
      width: 100%;

      .cart-item {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 8px;
        @include media-breakpoint-up(md) {
          width: 80%;
        }
        width: 100%;
        border-radius: 0.625vw;
        border: 1px solid #dde2eb;
        transition: all 300ms ease-out;

        &:hover {
          box-shadow: 0px 0.694vw 0.694vw 0 rgba(0, 0, 0, 0.1);
          .item-picture {
            .bullets {
              display: none;
            }

            .btn-delete {
              display: flex;
            }
          }
        }

        .item-picture {
          position: relative;
          margin: 0 auto 0.417vw;
          width: auto;
          height: auto;
          border-radius: 0.417vw;

          .product-image {
            width: 100%;
            height: 90px;
            object-fit: contain;
            object-position: center;
            border-radius: 0.417vw;
          }

          .brand-logo {
            position: absolute;
            top: 0.347vw;
            left: 0.347vw;
            right: 0;
            bottom: 0;
            width: 1.944vw;
            height: 1.944vw;
            object-fit: contain;
            object-position: center;
            z-index: 2;

            .rtl & {
              right: 0.347vw;
              left: 0;
            }
          }

          .bullets {
            position: absolute;
            top: -0.417vw;
            right: -0.417vw;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.347vw;
            background: #eeeeee;
            border-radius: 0.556vw;
            z-index: 3;

            .rtl & {
              right: unset;
              left: -0.417vw;
            }

            .bullet {
              margin: 0 0.104vw;
              width: 0.417vw;
              height: 0.417vw;
              border: 1px solid #28374b;
              border-radius: 50%;

              &.active {
                background-color: #28374b;
              }
            }
          }

          .btn-delete {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
            justify-content: center;
            align-items: center;
            width: 1.944vw;
            height: 1.944vw;
            background-color: #838383;
            border-radius: 50%;
            z-index: 2;

            .rtl & {
              right: unset;
              left: 0;
            }

            img {
              width: 1.042vw;
            }
          }

          .btn-customization {
            position: absolute;
            left: get-vw-desktop(5px);
            bottom: get-vw-desktop(5px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: get-vw-desktop(20px);
            height: get-vw-desktop(20px);
            background-color: var(--primary-store);
            border-radius: get-vw-desktop(10px);
            z-index: 2;
            overflow: hidden;

            transition: width 300ms ease-in-out;

            .rtl & {
              left: unset;
              right: get-vw-desktop(5px);
            }

            &:hover {
              width: get-vw-desktop(95px);
            }

            .icon {
              margin: get-vw-desktop(1px) get-vw-desktop(5px) 0;
              width: get-vw-desktop(12px);
              height: get-vw-desktop(12px);
            }

            .text {
              color: #ffffff;
              font-size: get-vw-desktop(9.5px);
              font-weight: 600;
              text-align: center;
              text-transform: uppercase;
              line-height: 1;
            }
          }
        }

        .item-title {
          margin-bottom: 0.417vw;
          font-size: 0.903vw;
          line-height: 1.083vw;
          @extend .line-clamp-2;
        }

        .item-price {
          margin-bottom: 0.556vw;
          color: var(--primary-store);
          font-style: normal;
          font-weight: 600;
          font-size: 14px;

          .previous-price {
            color: #5e6978;
            font-size: 12px;
            font-weight: normal;
            text-decoration: line-through;
          }
        }

        .item-properties {
          padding-top: 5px;
          border-top: 1px solid #dde2eb;
          .property {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 0.833vw;
            line-height: 1.389vw;
            &.quantity-container {
              justify-content: space-between;
              margin-top: 10px;
            }
            .property-name {
              margin-right: 0.278vw;
              color: #949ba5;
            }
            .property-value {
              color: #000;
              font-size: 14px;
            }
            .btn-minus {
              .rtl & {
                margin-left: unset;
                margin-right: auto;
              }

              img {
                width: 100%;
              }
            }
            .btn-add {
              width: 1.458vw;
              height: 1.458vw;
              margin-left: 0.347vw;
              .rtl & {
                margin-left: unset;
                margin-right: 0.347vw;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    //////////////////////////////////////////
    // Empty cart
    //////////////////////////////////////////
    .empty-cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;

      .border-holder {
        padding: 40px 20px;

        img {
          margin-bottom: 20px;
        }

        p {
          font-size: 15px;
          line-height: 113.9%;
          color: #5e6978;
        }
      }
    }

    //////////////////////////////////////////
    // Bottom elements
    //////////////////////////////////////////
    .total-sum {
      font-weight: 300;
      font-size: 1.25vw;
      line-height: 1.528vw;
      color: #b0b5bf;
      text-align: center;

      .discount-sum {
        color: var(--secondary-store);
      }
    }

    .btn-checkout {
      padding: 0 0.347vw;
      height: 2.778vw;
      border-radius: 0.347vw;
      font-size: 1.111vw;

      .sum {
        font-weight: normal;
        min-width: 8rem;
      }

      .seperator {
        height: calc(100% - 0.694vw);
        margin: 0.347vw;
        width: 1px;
        background-color: #fff;
        border: 0;
      }
    }
  }
}

//////////////////////////////////////////
// Mobile Session
//////////////////////////////////////////
.session-screen-mobile {
  position: relative;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  // height: 100%;
  width: 100%;
  overflow: hidden;
}

.flickeringElementsClass {
  animation-name: flickeringAnimation;
  animation-duration: 1.9s;
  animation-iteration-count: infinite;
  transition: all 2.3s ease-in-out;
  transform: translateZ(0);
}
@keyframes flickeringAnimation {
  0% {
    transform: scale(1) rotate3d(1, 1, 1, 0deg);
  }
  20% {
    transform: scale(1) rotate3d(1, 1, 1, 0deg);
  }
  30% {
    transform: scale(1) rotate3d(1, 1, 1, 0deg);
  }
  40% {
    transform: scale(1.3) rotate3d(1, 1, 1, -14deg);
  }
  50% {
    transform: scale(1.3) rotate3d(1, 1, 1, 14deg);
  }
  60% {
    transform: scale(1.3) rotate3d(1, 1, 1, -14deg);
  }
  70% {
    transform: scale(1.3) rotate3d(1, 1, 1, 14deg);
  }
  80% {
    transform: scale(1.3) rotate3d(1, 1, 1, -14deg);
  }
  90% {
    transform: scale(1.3) rotate3d(1, 1, 1, 14deg);
  }
  100% {
    transform: scale(1) rotate3d(1, 1, 1, 0deg);
  }
}

.flickeringElementsClass-cart {
  animation-name: flickeringAnimationCart;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transition: all 2.3s ease-in-out;
  left: -80px;
  transform: translateZ(0);
}
@keyframes flickeringAnimationCart {
  10% {
    transform: rotate(-15deg);
  }

  20% {
    left: 0px;
    transform: rotate(0deg);
  }

  70% {
    left: 0px;
  }

  90% {
    // opacity: 0.3;
    left: 70px;

    display: none;
    visibility: hidden;
  }

  100% {
    // opacity: 0.4;
    left: -80px;
    display: none;
    visibility: hidden;
  }
}
//////////////////////////////////////////
// Session is full
//////////////////////////////////////////
.session-is-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(#fff, 0.7);
  backdrop-filter: blur(50px);
  z-index: 1000;

  img {
    margin-bottom: 30px;
    width: 50vw;
    height: 50vw;
    @include media-breakpoint-up(xl) {
      width: 20vw;
      height: 20vw;
    }
  }

  p {
    padding: 0 10vw;
    font-weight: 600;
    font-size: 4.267vw;
    text-align: center;
    @include media-breakpoint-up(xl) {
      padding: 0;
      font-size: 1vw;
    }
  }
}
