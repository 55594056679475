//////////////////////////////////////////
// Chat Mobile
//////////////////////////////////////////
.chat-wrapper-mobile {
  position: absolute;
  top: 126px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  z-index: 3;
  pointer-events: none;

  .rtl & {
    left: unset;
    right: 0;
  }

  & > * {
    pointer-events: all;
    width: calc(100% - 55px);
  }

  .btn-close-chat {
    position: relative;
    align-self: flex-start;
    margin-left: 10px;
    margin-bottom: 10px;

    .rtl & {
      margin-left: unset;
      margin-right: 10px;
    }
  }

  .new-message-notification {
    border-radius: 24px;
    width: 7px;
    height: 7px;
    background: #ff3d3d;
    margin-bottom: 6px;
  }
  .close-chat {
    width: 24px;
    height: 24px;
    background: rgba(153, 153, 153, 0.3);
    backdrop-filter: blur(15px);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat {
    display: flex;
    height: 0;
    flex-direction: column-reverse;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transition: flex-grow 300ms ease-out;
    &.open {
      height: auto;
      max-height: calc(var(--vh, 1vh) * 40);
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
      display: none;
    }

    .chat-messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      gap: 8px;

      .rtl & {
        padding-left: 10px;
        padding-right: 10px;
      }

      //////////////////////////////////////////
      // Message item
      //////////////////////////////////////////
      .message-item {
        margin: 3px 0;
        padding: 8px 10px;
        line-height: 1;
        background-color: rgba(#898989, 0.5);
        border-radius: 7px;
        backdrop-filter: blur(10px);
        flex-direction: row;
        align-items: center;
        .message-item {
          padding-top: 0px;
          padding-bottom: 0px;
          padding-right: 4px;
          padding-left: 4px;
        }
        .message-info {
          flex-direction: column;
          .message-body {
            margin-top: 0;
            margin-bottom: 0;
            background-color: unset;
            backdrop-filter: unset;
          }
        }
        .user-name {
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          white-space: nowrap;

          &.host {
            .user-icon {
              circle {
                fill: #cc8fff;
              }
              path {
                fill: #cc8fff;
              }
            }
          }
          &.user {
            .user-icon {
              circle {
                fill: #ffcf5c;
              }
              path {
                fill: #ffcf5c;
              }
            }
          }
        }

        .message-text {
          padding-left: 8px;
          font-size: 12px;
          color: #fff;
          word-break: break-word;
          white-space: pre-wrap;
          font-weight: 400;
          .rtl & {
            padding-left: unset;
            padding-right: 8px;
          }
          a {
            color: #043979;
          }
        }

        &.message-vote {
          background-image: linear-gradient(180deg, #cc8fff 0%, #e98fff 100%);
          .vote-icon {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-weight: 600;
            .icon {
              margin-left: 5px;
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
              .rtl & {
                margin-right: 5px;
                margin-left: 10px;
              }
            }
          }
          .content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            font-size: 11px;

            .product-image {
              margin: 0 3px;
              width: 30px;
              height: 30px;
              border-radius: 5px;
            }
          }
        }

        &.message-alert {
          background-color: #070d45;
          .icon {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            .rtl & {
              margin-right: unset;
              margin-left: 10px;
            }
          }
          .content {
            margin-left: 0;
            font-weight: 600;
            font-size: 11px;
            line-height: 1.3;
            color: #ffffff;
            .rtl & {
              margin-left: unset;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  // chat input
  .chat-bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;

    .btn-cart {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      flex: 0 0 42px;
      color: #fff;
      background-color: transparent;
      z-index: 5;

      &.empty {
        .badge {
          display: none;
        }
      }

      i {
        font-size: 32px;
      }
    }

    .btn-pause-broadcast,
    .btn-start-broadcast {
      flex: 0 0 42px;
      img {
        width: 42px;
        height: 42px;
      }
    }

    .chat-input-wrapper {
      position: relative;
      flex: 1 1 100%;
      .chat-input {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 12px);
        padding: 13px 36px 10px 12px;
        height: 36px;
        color: #f6f7fb;
        font-size: 4vw;
        line-height: 1;
        background-color: rgba(#000, 0.4);
        backdrop-filter: blur(30px);
        border-radius: 18px;
        border: 0;
        transition: width 200ms ease-out;
        resize: none;
        appearance: none;
        overflow: hidden;

        @media (min-width: 400px) {
          font-size: 14px;
        }

        .rtl & {
          padding: 13px 12px 10px 36px;
        }

        &::placeholder {
          color: #b0b5bf;
        }
      }

      .btn-send {
        position: absolute;
        top: 0;
        right: 6px;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        color: #fff;
        .rtl & {
          right: unset;
          left: 6px;
        }
        i {
          font-size: 20px;
          transform: rotate(45deg);
          .rtl & {
            transform: rotate(225deg);
          }
        }
      }
    }

    app-like-button {
      flex: 0 0 42px;
      height: 42px;
      .btn-like {
        width: 42px;
        .circle-bg {
          position: relative;
          height: 42px;
          background-color: transparent;
          overflow: visible;

          .lottie-like {
            position: absolute;
            width: 74px;
            left: -16px;
            bottom: 0;
            pointer-events: none;
            .rtl & {
              left: unset;
              right: -16px;
            }
          }
        }
      }
    }
  }
  .flickeringItems-like {
    position: absolute;
    bottom: 19px;
    height: 27px;
    width: 36px;
    right: 13px;
    z-index: 8;
  }
}
.open-chat-btn-container {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  gap: 6px;
  height: 24px;
  background: rgba(153, 153, 153, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 60px;
  align-items: center;
}
.live-chat-btn-text {
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  flex: none;
  flex-grow: 0;
}
#flickeringElements-tooltipLike {
  position: absolute;
  top: 367px;
  height: 255px;
  width: 325px;
  right: -1px;
  background-color: #ee808a;
  color: white;
  font-size: 34px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  padding: 10px;
  border-radius: 61%;
  border-top-right-radius: 242px;
  border-bottom-right-radius: 10px;
  transform: rotate(376deg);
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform: translateZ(0);
}
@keyframes example {
  0% {
    border-top-right-radius: 212px;
  }
  25% {
    border-top-right-radius: -122px;
  }
  50% {
    border-top-right-radius: 180px;
  }
  100% {
    border-top-right-radius: 282px;
  }
}

#flickeringElements-tooltipLike-text {
  position: absolute;
  top: 53px;
  font-size: 28px;
  width: 206px;
  left: 70px;
  text-shadow: 0 0 19px black;
  transform: rotate(345deg);
}
