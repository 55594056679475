.container-modal {
  z-index: 9999;
  position: absolute;
  top: 16vh;
  border-radius: 12px;
  background: var(--Schemes-On-Secondary, #fff);
  box-shadow: -2px -3px 11px 0px rgba(28, 37, 44, 0.08);
}
.rtl {
  .live-session-content-mobile {
    .right-side-column .products-btn-img .block-featured-items {
      left: 12.4vw;
      right: unset;
    }
  }
}
.live-session-content-mobile {
  position: relative;
  height: 100%;
  width: 100%;

  .top-row {
    position: absolute;
    top: 8px;
    left: 6px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    .rtl & {
      left: unset;
      right: 6px;
    }

    .badge-live {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 0 6px;
      height: auto;
      max-width: 168px;
      border-radius: 17px;
      direction: ltr !important;

      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }

      &.is-brodcasting {
        &::after {
          content: '•';
          color: #ff4b33;
          font-weight: 600;
          font-size: 22px;
          line-height: 1;
        }
      }

      .avatar {
        width: 26px;
        height: 26px;
        aspect-ratio: 1;
        border: 1px solid #fff;
        overflow: visible;
        border-radius: 50%;
        line-height: 1;

        .rtl & {
          margin-right: unset;
          margin-left: 10px;
        }

        .host-picture {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 15px;
        }
      }

      .host-name {
        margin-left: 10px;
        margin-right: 8px;
        font-weight: 500;
        font-size: 11px;
        line-height: 1;
        color: #fff;
        width: auto;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .rtl & {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
    .badge-views {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 0 8px;
      height: 12px;
      border-radius: 17px;
      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }
      .icon {
        margin-right: 8px;
        width: 10px;
        .rtl & {
          margin-right: unset;
          margin-left: 8px;
        }
      }
      .views {
        font-size: 11px;
        line-height: 1;
        color: #fff;
      }
    }

    .btn-mute {
      img {
        width: 34px;
      }
    }
  }

  .right-side-column {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 4;
    width: 65px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;

    .rtl & {
      right: unset;
      left: 6px;
    }
    .products-btn-img {
      flex-direction: column;
      .block-featured-items {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 12vw;
        top: 0;
        height: 60vh;
        row-gap: 5px;
        width: 20vw;

        .list-view-item {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          overflow: auto;
          height: 37vh;
          width: 100%;
          align-items: center;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */
          &::-webkit-scrollbar {
            /* WebKit */
            width: 0;
            height: 0;
            display: none;
          }
          .featured-item {
            width: 15vw;
          }
        }
      }
    }
    .btn-view-item {
      width: 27vw;
      color: #fff;
      font-size: 10px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(#000, 0.4);
      z-index: 3;
      text-transform: uppercase;

      img {
        width: 100%;
        height: 30vw;
        background-color: #fff;
        border-radius: 6px;
        object-fit: contain;
        object-position: center;
        position: relative;
        object-fit: cover;
      }
    }

    .added-to-cart {
      display: flex;
      align-items: center;
      margin-top: 3px;
      padding: 0 6px;
      height: 25px;
      width: 27vw;
      font-size: 11px;
      line-height: 1;
      color: #fff;
      border-radius: 6px;
      background-color: rgba(#000, 0.4);
      justify-content: center;
      img {
        margin-right: 5px;
        font-size: 20px;

        .rtl & {
          margin-right: unset;
          margin-left: 5px;
        }
      }
    }

    .mute-card-live {
      position: relative;
      left: 1rem;
    }
    .flickeringElementsClass {
      animation-name: flickeringAnimations;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      transform: scale(1) rotate3d(1, 1, 1, 14deg);
    }

    @keyframes flickeringAnimations {
      0% {
        transform: scale(1) rotate3d(1, 1, 1, 0deg);
      }
      20% {
        transform: scale(1) rotate3d(1, 1, 1, 0deg);
      }
      30% {
        transform: scale(1) rotate3d(1, 1, 1, 0deg);
      }
      40% {
        transform: scale(1.1) rotate3d(1, 1, 1, -14deg);
      }
      50% {
        transform: scale(1.1) rotate3d(1, 1, 1, 14deg);
      }
      60% {
        transform: scale(1.1) rotate3d(1, 1, 1, -14deg);
      }
      70% {
        transform: scale(1.1) rotate3d(1, 1, 1, 14deg);
      }
      80% {
        transform: scale(1.1) rotate3d(1, 1, 1, -14deg);
      }
      90% {
        transform: scale(1.1) rotate3d(1, 1, 1, 14deg);
      }
      100% {
        transform: scale(1) rotate3d(1, 1, 1, 0deg);
      }
    }

    #flickeringElements_featuredItem {
      position: absolute;
      top: 7px;
      left: 7px;
      height: 50px;
      width: 50px;
      border: 5px solid rgba(255, 0, 0, 0.622);
      animation-name: example;
      animation-duration: 1.9s;
      animation-iteration-count: infinite;
      transition: all 2.3s ease-in-out;
      border-radius: 6px;
      transform: translateZ(0);
    }
    @keyframes example {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(0.8);
        opacity: 0;
      }
    }

    .btn-polls {
      position: relative;
      background-color: var(--primary-store);
      border-radius: 50%;
      height: 34px;
      width: 34px;

      .badge {
        position: absolute;
        background-color: #cfcfcf;
        border: 1px solid #babdbe;
        border-radius: 50%;
        color: #474649;
        font-size: 8px;
        font-weight: 600;
        height: 20px;
        left: -6px;
        line-height: 14px;
        top: -6px;
        width: 20px;

        .rtl & {
          left: 0;
          right: -6px;
        }
      }
    }

    #flickeringItem-pools {
      position: relative;
      /* transform: rotate(270deg); */
      border-radius: 50%;
      height: 36px;
      width: 36px;
      /* background-color: #eb6792; */
      top: 9px;
      padding: 3px;
      right: -31px;
      background-image: linear-gradient(118deg, rgb(239 130 90), rgb(235 96 165 / 97%));
    }

    .btn-analytics {
      position: relative;
      margin: 15px 0 0 auto;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #0006;
      border-radius: 50%;
      backdrop-filter: blur(10px);

      .rtl & {
        margin: 15px auto 0 0;
      }

      i {
        font-size: 20px;
      }
    }

    //////////////////////////////////////////
    // Discount Coupon
    //////////////////////////////////////////

    .discount-coupon-value {
      padding: 8px 4px 8px 4px;
      border-radius: 12%;
      margin-right: 10px;
      background-color: var(--primary-store);
      font-weight: 700;
      font-size: 10px;
      line-height: 1;
      z-index: 3;
      color: var(--light-store);
    }

    //////////////////////////////////////////
    // Prograss bar
    //////////////////////////////////////////
    .progress-bar {
      position: relative;
      width: 38px;
      height: 250px;
      text-align: center;
      background-color: #dcdcdc;
      border-radius: 6px;
      overflow: hidden;
      z-index: 1;
      display: flex;
      align-items: center;

      .rtl & {
        margin: 20px auto 0 0;
      }

      .btn-view-item {
        position: absolute;
        top: 31px;

        width: 34px;
        height: 34px;
        border-radius: 5px;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &.unavailable {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            background-image: url('/assets/images/lock.svg');
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            background-size: 28%;
            z-index: 1;
          }
        }
      }

      .goal-coupon {
        position: absolute;
        top: 2px;
        z-index: 3;
      }

      .progress-bar-fill {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        min-height: 20px;
        max-height: calc(100% - 58px);
        background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
        transition: height 2000ms ease-out;

        // When we have a coupon at the bottom
        &.coupon-reached {
          min-height: 44px;
        }

        // When reached 100% precent
        &.max-reached {
          max-height: none;

          // Hide the wave
          .fill-wave {
            display: none;
          }
          // lower the logo with text
          .floater {
            transform: translate(0, 58px);
          }
        }

        // When under 100% precent with space reserved for .goal-discount-product
        &.with-item-discount {
          max-height: calc(100% - 10vw);
          &.coupon-reached {
            min-height: 70px;
          }
          &.max-reached {
            max-height: none;
            .fill-wave {
              display: none;
            }
            .floater {
              transform: translate(0, 80px);
            }
          }
        }

        .floater {
          position: absolute;
          top: -12.5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background: #ffffff;
            box-shadow: 0 0.556vw 0.694vw rgba(184, 107, 249, 0.2);
            border-radius: 50%;

            img {
              width: 8px;
            }
          }
        }
      }

      .progress-bar-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;

        app-start-coupon {
          margin: 0 auto 4px;
        }

        .value {
          padding-top: 6px;
          font-size: 11px;
          background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
        }

        .start-coupon {
          height: 22px;

          background-image: url('/assets/images/progress-bar-bottom-coupon.svg');
        }

        .btn-view-item {
          position: relative;
          margin: 0 auto 2px;
          top: 0;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Products pull up
  //////////////////////////////////////////
  .pull-up-container.products-pull-up {
    .pull-up-content {
      .pull-up-body {
        .products-wrapper {
          .products {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
            width: 100%;
            z-index: 1;
          }
          .products-mobile {
            flex-wrap: wrap;
            flex-direction: column;
            gap: 12px;
          }
          .product-separator {
            justify-content: center;
          }
          .separator {
            width: 90vw;
            height: 1px;
            background: #dfe2eb;
          }
        }
      }
    }
  }

  //////////////////////////////////////////
  // Polls pull up
  //////////////////////////////////////////
  .pull-up-container.polls-pull-up {
    .pull-up-content {
      height: 100%;
      padding: 0 20px;
    }
  }

  //////////////////////////////////////////
  // Analytics pull up
  //////////////////////////////////////////
  .pull-up-container.analytics-pull-up {
    .pull-up-content {
      .pull-up-body {
        // padding: 0 20px;

        //////////////////////////////////////////
        // Analytics
        //////////////////////////////////////////
        .stats-container {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          width: 100%;

          .stat-item {
            flex: 0 1 calc(100% / 2);
            margin-bottom: 2.083vw;
            padding-right: 1.389vw;

            font-weight: 700;
            font-size: 20px;
            line-height: 1.5;

            .rtl & {
              padding-right: unset;
              padding-left: 1.389vw;
            }

            .stat-value {
              color: var(--primary-store);
            }
          }
        }

        .products-wrapper {
          margin: 20px -20px;
          .products {
            padding-top: 0.833vw;
          }
        }
      }
    }
  }
}
