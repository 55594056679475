.custom-scroll {
  &::-webkit-scrollbar {
    // Entire scrollbar
    height: 4px; // For vertical scrollbars
    width: 4px; // For horizontal scrollbars
  }

  &::-webkit-scrollbar-track {
    // Entire progress bar area of the scrollbar
    background: #f9fafb;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    // Draggable section of the scrollbar
    background: #b7bcc5;
    border-radius: 2px;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // Scrolling styles for Firefox
  scrollbar-color: #b7bcc5 #f9fafb;
  scrollbar-width: thin;
}
