//////////////////////////////////////////
// Chat
//////////////////////////////////////////
.chat-wrapper-desktop {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;

  .chat {
    display: contents;
    flex-grow: 1;
    margin-right: -22px;
    margin-top: 0.694vw;
    margin-bottom: 10px;

    .rtl & {
      margin-right: unset;
      margin-left: -22px;
    }

    .chat-messages {
      padding-right: 22px;
      .rtl & {
        padding-right: unset;
        padding-left: 12px;
      }

      //////////////////////////////////////////
      // Message item
      //////////////////////////////////////////
      .message-item {
        display: flex;
        align-items: center;
        margin: 0.556vw 0;
        padding: 0.694vw 0.486vw;
        background-color: rgba(#fff, 0.7);
        border-radius: 0.486vw;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        .avatar {
          flex: 0 0 2.083vw;
          width: 2.083vw;
          height: 2.083vw;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          margin-left: 0.65vw;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-left: 1.111vw;
          color: #000;
          width: 100%;
          .rtl & {
            margin-left: unset;
            margin-right: 1.111vw;
          }

          .user-name {
            font-size: 0.903vw;
            font-weight: 600;
            line-height: 130%;
            letter-spacing: -0.03em;
          }
          .message-text {
            font-size: 0.903vw;
            line-height: 130%;
            word-break: break-word;
            a {
              color: #043979;
            }
            // white-space: pre;
          }
        }

        &.message-vote {
          .vote-icon {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-weight: 600;
            font-size: 0.833vw;
            .icon {
              margin-right: 0.694vw;
              width: 1.389vw;
              height: 1.389vw;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;

              .rtl & {
                margin-right: unset;
                margin-left: 0.694vw;
              }
            }
          }
          .content {
            margin-left: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            .rtl & {
              margin-left: unset;
              margin-right: 0;
            }

            .product-image {
              margin: 0 0.208vw;
              width: 2.222vw;
              height: 2.222vw;
              border-radius: 0.347vw;
              object-fit: contain;
              object-position: center;
            }
          }
        }

        &.message-alert {
          background-color: #070d45;
          .icon {
            margin-right: 0.972vw;
            width: 2.083vw;
            height: 2.083vw;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            .rtl & {
              margin-right: unset;
              margin-left: 0.972vw;
            }
          }
          .content {
            margin-left: 0;
            font-weight: 600;
            font-size: 0.764vw;
            line-height: 1.111vw;
            color: #ffffff;
            .rtl & {
              margin-left: unset;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .app-chat-promo-product {
    margin-right: 18px;
    margin-top: 10px;
  }
  .chat-emojis-bar {
    margin-top: 28px;
    display: flex;
    margin-bottom: 14px;
    .emoji {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.556vw;
      padding: 0 0.833vw;
      height: 2.083vw;
      font-size: 0.972vw;
      color: #fff;
      background-color: rgba(137, 137, 137, 0.5);
      border-radius: 1.042vw;
      .rtl & {
        margin-right: unset;
        margin-left: 0.556vw;
      }
    }
  }

  .chat-input-wrapper {
    position: relative;
    .chat-input {
      padding: 0.6vw 2.917vw 0.6vw 1.111vw;
      //height: 2.639vw;
      width: 100%;
      color: #000;
      font-size: 0.972vw;
      line-height: 1.5;
      background-color: #feffff;
      border: 0;
      border-radius: 0.556vw;
      box-shadow: 0 0.556vw 0.694vw rgba(25, 29, 56, 0.1);
      transition: background-color 300ms ease-out;
      resize: none;
      appearance: none;
      overflow: hidden;
      .rtl & {
        padding: 0.6vw 2.917vw 0.6vw 1.111vw;
      }

      &::placeholder {
        color: #737588;
      }

      &:focus {
        outline: 0;
        background-color: #dde2eb;
      }
    }
    .btn-send {
      position: absolute;
      top: 0;
      right: 0;
      height: 2.639vw;
      padding: 0 0.694vw;
      margin-top: 4px;

      .rtl & {
        right: unset;
        left: 0;
      }
      img {
        width: 1.528vw;
        .rtl & {
          transform: scaleX(-1);
        }
      }
    }
  }

  //////////////////////////////////////////
  // Empty chat
  //////////////////////////////////////////
  .empty-chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    .border-holder {
      padding: 20px;
      @include media-breakpoint-up(xl) {
        width: 29.286vw;
      }

      img {
        margin-bottom: 20px;
        width: 7.143vw;
      }

      p {
        font-size: 1.071vw;
        line-height: 114%;
        color: #5e6978;
      }
    }
  }
}
